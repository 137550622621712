<template>
  <v-container align="center" fluid class="d-flex justify-center mb-15">
    <!-- <v-card min-width="1000">
      <v-card-title class="ml-10 mt-5"> </v-card-title>
      <v-card-text>
        <v-row class="ml-10">
          <v-col cols="9" class="mt-7">
            <p style="font-size: 5rem" class="amber--text text--darken-4">
              INVOICE
            </p>
          </v-col>
          <v-col cols="2">
            <v-img height="100%" :src="companyinfo.logo_url"></v-img>
          </v-col>
        </v-row>

        <div id="dataapp">
          <v-row class="ml-10 mt-10">
            <v-col cols="4">
              <p class="font-weight-medium">Invoice Number:</p>
              <p>{{ invoiceinfo.id }}</p>
            </v-col>
            <v-col cols="4">
              <p class="font-weight-medium">Date of Issue:</p>
              <p>{{ invoiceinfo.date }}</p>
            </v-col>

            <v-col cols="4">
              <p class="font-weight-medium">Time:</p>
              <p>{{ invoiceinfo.time }}</p>
            </v-col>
          </v-row>

          <v-row class="ml-10 pa-0 mt-5">
            <v-col cols="3">
              <p class="font-weight-medium">Client Name</p>
            </v-col>
            <v-col cols="9">
              <p>: {{ bill.seller_email }}</p>
            </v-col>
            <v-col cols="3" style="padding-top: 0">
              <p class="font-weight-medium">Phone Number</p>
            </v-col>
            <v-col cols="9" style="padding-top: 0">
              <p>: {{ bill.seller_phone }}</p>
            </v-col>
            <v-col cols="3" style="padding-top: 0">
              <p class="font-weight-medium">Invoice Status</p>
            </v-col>
            <v-col cols="9" style="padding-top: 0">
              <p>: {{ orderinfo.admin_status }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="orders"
                item-key="id"
                class="elevation-0"
                hide-default-footer
              >
                <template v-slot:item.details="{ item }">
                  <v-icon @click="assignViewedItem(item)">mdi-eye</v-icon>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    color="success"
                    @click="ADDQuantity(item)"
                    v-show="
                      item.admin_status == 'Pending' &&
                      orderinfo.admin_status == 'Processing'
                    "
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8"> </v-col>
            <v-col cols="4">
              <v-col class="d-flex flex-row">
                <p class="font-weight-medium">Sub Total:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">{{ orderinfo.sub_price }}</p>
              </v-col>

              <v-col class="d-flex flex-row" style="padding-top: 0">
                <p class="font-weight-medium">Discount:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">{{ orderinfo.overall_discount }}</p>
              </v-col>
              <v-col class="d-flex flex-row" style="padding-top: 0">
                <p class="font-weight-medium">VAT:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">0</p>
              </v-col>
              <v-col class="d-flex flex-row" style="padding-top: 0">
                <p class="font-weight-medium">Grand Total:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">{{ orderinfo.price_total }}</p>
              </v-col>
            </v-col>
          </v-row>

          <v-row class="ml-2 d-flex flex-row align-center">
            <p class="font-weight-medium mb-0">
              Payment Status: {{ orderinfo.order_status }}
            </p>
            <p class="text-caption mb-0">
              This Invoice Is Autogenareted By {{ companyinfo.name }} After
              Confirming Your Order And Payment
            </p>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              class="mr-2"
              dark
              @click="AllConfirm"
              v-show="orders.length == finalArray.length"
            >
              Confirm All
            </v-btn>

            <v-dialog v-model="confirm_cancel" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-show="orderinfo.admin_status == 'Processing'"
                >
                  Cancel Invoice
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline grey lighten-2 pa-0 py-3">
                  Are You Sure ?
                </v-card-title>

                <v-card-text>
                  This action will cancel this invoice and its containing all
                  products.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    outlined
                    @click="confirm_cancel = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="red" outlined @click="CancelInvoice">
                    I accept
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
      </v-card-text>
    </v-card> -->

    <v-card class="pa-10">
      <v-card-text>
        <v-row style="border-bottom: 1px groove">
          <v-col cols="9" class="mt-7">
            <v-row class="d-flex flex-column">
              <v-col class="pt-0">
                <v-text style="font-size: 2.1rem; color: #757575">
                  INVOICE
                </v-text>
              </v-col>
              <v-col class="py-0"
                ><p class="font-weight-medium py-0 mb-0">BILL FROM :</p></v-col
              >
              <v-col class="pb-0"
                ><p class="mb-0 text-body-1">
                  {{ companyinfo.name }}
                </p></v-col
              >
              <v-col cols="4" class="py-0">
                <p class="text-body-1 mb-0">{{ companyinfo.address }}</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2" align-self="center">
            <div style="height: 70%; width: 70%">
              <v-img :src="companyinfo.logo_url" alt="Rooster crowing"></v-img>
            </div>
          </v-col>
        </v-row>

        <div>
          <v-row class="pa-0 mt-5">
            <v-col class="py-0 mb-0" cols="8"
              ><p class="mb-0 font-weight-medium">BILL TO :</p></v-col
            >
            <v-col cols="4" class="py-0 d-flex flex-row">
              <p class="text-body-1 mb-0 font-weight-bold">INVOICE #</p>
              <v-spacer></v-spacer>
              <p class="text-body-1 mb-0 mr-13 font-weight-bold">{{ invoiceinfo.id }}</p>
            </v-col>

            <v-col cols="8" class="py-0">
              <p class="text-body-1 mb-0 mt-2">
                {{ bill.seller_username }}
              </p>
              <p class="text-body-1 mb-0">
                {{ bill.seller_email }}
              </p>
            </v-col>
            <v-col cols="4" class="py-0 d-flex flex-row">
              <v-col class="py-0 d-flex flex-row pl-0 mr-5">
                <p class="text-body-1 mb-0">Date:</p>
                <v-spacer></v-spacer>
                <p class="text-body-1 mb-0 mr-5">{{ invoiceinfo.date }}</p>
              </v-col>
            </v-col>
            <v-col cols="8" class="py-0">
              <p class="text-body-1 mb-0">
                {{ bill.seller_phone }}
              </p>
              <p class="text-body-1 mb-0">{{ bill.address }}</p>
              <p class="text-body-1 mb-0">{{ bill.location }}</p>
            </v-col>
            <v-col
              cols="4"
              v-if="orderinfo.order_status == 'Unpaid'"
              class="py-0"
              dense
            >
              <!-- <div
                class="d-flex flex-row align-center mr-12"
                style="background-color: #cfd3d4"
              >
                <p class="mt-2 mb-1 font-weight-medium ml-2 mb-2">
                  Amount Due:
                </p>
                <v-spacer></v-spacer>
                <p class="mt-2 mb-1 font-weight-medium mr-5">{{ invoiceinfo.due }}</p>
              </div> -->
              <div>
                <p
                  style="font-family: 'PT Serif', serif; bold; font-size:30px;"
                  class="mt-3 mb-2 font-weight-bold"
                >
                  UNPAID
                </p>
              </div>
            </v-col>
            <v-col
              cols="4"
              v-if="orderinfo.order_status == 'Paid'"
              class="py-0"
            >
              <p
                style="font-family: 'PT Serif', serif; font-size: 30px"
                class="mt-2 mb-0 font-weight-bold"
              >
                PAID
              </p>
            </v-col>
          </v-row>

          <!-- Table -->
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="orders"
                item-key="id"
                class="elevation-0 "
                hide-default-footer
              >
                <template v-slot:item.details="{ item }">
                  <v-icon @click="assignViewedItem(item)">mdi-eye</v-icon>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    color="success"
                    @click="ADDQuantity(item)"
                    v-show="
                      item.admin_status == 'Pending' &&
                      orderinfo.admin_status == 'Processing'
                    "
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8"> </v-col>
            <v-col cols="4">
              <v-col class="d-flex flex-row">
                <p class="font-weight-medium">Sub Total:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">{{ orderinfo.sub_price }}</p>
              </v-col>

              <v-col class="d-flex flex-row" style="padding-top: 0">
                <p class="font-weight-medium">Discount:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">{{ orderinfo.overall_discount }}</p>
              </v-col>
              <v-col class="d-flex flex-row" style="padding-top: 0">
                <p class="font-weight-medium">VAT:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">0</p>
              </v-col>
              <v-col class="d-flex flex-row" style="padding-top: 0">
                <p class="font-weight-medium">Grand Total:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">{{ orderinfo.price_total }}</p>
              </v-col>
            </v-col>
          </v-row>

          <!-- <v-card v-if="orderinfo.order_status == 'Paid'" elevation="0">
            <div>
              <div style="border: 1px groove" class="mx-12">
                <v-row>
                  <v-row class="ml-5">
                    <v-col class="" cols="3"> Transaction Date </v-col>
                    <v-col cols="3"> GateWay</v-col>
                    <v-col cols="3"> Transaction ID </v-col>
                    <v-col cols="3"> Amount</v-col>
                  </v-row>
                </v-row>
              </div>
              <div style="border: 1px groove" class="mx-12">
                <v-row>
                  <v-row class="ml-5">
                    <v-col class="" cols="3">
                      <p class="text-body-1 mb-0" >
                        {{ orderinfo.ordered_date }}
                      </p>
                    </v-col>
                    <v-col cols="3" class="text-body-1 mb-0"> N/A</v-col>
                    <v-col cols="3" class="text-body-1 mb-0"> N/A </v-col>
                    <v-col cols="3">
                      <p v-if="orderinfo.grand_total" class="text-body-1 mb-0">
                        {{ orderinfo.grand_total }}
                      </p>
                    </v-col>
                  </v-row>
                </v-row>
              </div>
            </div>
          </v-card> -->

          <v-row class="d-flex flex-row align-center">
            <p class="text-caption">
              This Invoice Is Autogenareted By {{ companyinfo.name }} After
              Confirming Your Order And Payment
            </p>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              class="mr-2"
              dark
              @click="AllConfirm"
              v-show="orders.length == finalArray.length && !loading"
            >
              Confirm All
            </v-btn>

            <v-dialog v-model="confirm_cancel" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-show="orderinfo.admin_status == 'Processing'"
                >
                  Cancel Invoice
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline grey lighten-2 pa-0 py-3">
                  Are You Sure ?
                </v-card-title>

                <v-card-text>
                  This action will cancel this invoice and its containing all
                  products.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    outlined
                    @click="confirm_cancel = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="red" outlined @click="CancelInvoice">
                    I accept
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <!-- Product details -->
    <v-dialog
      v-model="dialog"
      transition="scale-transition"
      origin="top right"
      :nudge-left="500"
      max-width="900px"
      max-height="650"
    >
      <v-card class="pa-5">
        <v-card class="pa-5" elevation="0">
          <v-app-bar absolute color="white" flat>
            <v-toolbar-title class="font-weight-bold"
              >Products Details</v-toolbar-title
            >

            <v-spacer></v-spacer>
            <v-btn
              class=""
              icon
              elevation="0"
              color="red"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>

          <v-card class="mx-auto mt-12" elevation="0">
            <v-row>
              <v-carousel :show-arrows="true">
                <v-carousel-item
                  v-for="(item, i) in specification.images"
                  :key="i"
                  :src="item"
                ></v-carousel-item>
              </v-carousel>
            </v-row>

            <v-card-text>
              <v-row align="center">
                <v-col cols="6">
                  <p class="font-weight-medium">
                    Product SKU :
                    <span class="font-weight-regular ml-1">{{
                      specification.SKU
                    }}</span>
                  </p>
                </v-col>
                <v-col cols="6"
                  ><p class="font-weight-medium">
                    Product Title :
                    <span class="font-weight-regular">{{
                      specification.product_name
                    }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="font-weight-medium">
                    Category :
                    <span class="font-weight-regular">{{
                      specification.category
                    }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="font-weight-medium">
                    Sub Category :
                    <span class="font-weight-regular">{{
                      specification.sub_category
                    }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="font-weight-medium">
                    Sub Sub Category :
                    <span class="font-weight-regular">{{
                      specification.sub_sub_category
                    }}</span>
                  </p>
                </v-col>

                <v-col cols="6">
                  <p class="font-weight-medium">
                    Color :
                    <span class="font-weight-regular"
                      >{{ specification.color }}
                    </span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="font-weight-medium">
                    Size :
                    <span class="font-weight-regular">{{
                      specification.size
                    }}</span>
                  </p>
                </v-col>

                <v-col cols="6">
                  <p class="font-weight-medium">
                    Brand :
                    <span class="font-weight-regular"
                      >{{ specification.product_brand }}
                    </span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="font-weight-medium">
                    Warrenty :
                    <span class="font-weight-regular">{{
                      specification.warranty
                    }}</span>
                  </p>
                </v-col>

                <v-col cols="6">
                  <p class="font-weight-medium">
                    Variant :
                    <span class="font-weight-regular">{{
                      specification.weight_unit
                    }}</span>
                  </p>
                </v-col>
                <!-- <v-col cols="6"> Unit : {{ specification.unit }} </v-col> -->

                <v-col cols="6">
                  <p class="font-weight-medium">
                    Origin :
                    <span class="font-weight-regular">{{
                      specification.origin
                    }}</span>
                  </p>
                </v-col>
                <v-col cols="6"
                  ><p class="font-weight-medium">
                    Shipping Country :
                    <span class="font-weight-regular">{{
                      specification.shipping_country
                    }}</span>
                  </p>
                </v-col>

                <v-col cols="12">
                  <p class="font-weight-medium">Product Description</p>
                  {{ specification.description }}
                </v-col>

                <v-col cols="12">
                  <div>
                    <p class="font-weight-medium">Key Features :</p>

                    <ul>
                      <li
                        class="mt-5"
                        v-for="(
                          itemfeatures, index
                        ) in specification.key_features"
                        :key="index"
                      >
                        {{ itemfeatures }}
                      </li>
                    </ul>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </v-card>
    </v-dialog>

    <!-- Add quantity and price for this product -->
    <v-dialog v-model="addquantitydialog" width="750px" scrollable>
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title
            >Product Quantity: {{ addItem.total_quantity }}</v-toolbar-title
          >
          <v-spacer></v-spacer>

          <v-btn outlined @click="addquantitydialog = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-divider></v-divider>
        <v-card-text class="pt-0">
          <v-card-title class="py-2 px-0 font-weight-bold"
            >Price Information</v-card-title
          >

          <v-form v-model="priceValidationForm" ref="priceForm">
            <v-row>
              <v-col>
                <p>Purchase Price : {{ addItem.unit_price }}</p>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="addItem.price"
                  label="Selling Price"
                  required
                  type="number"
                  :rules="[(v) => !!v || 'Selling Price is required']"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="addItem.point_amount"
                  label="Point Amount Per Hundred"
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col>
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="addItem.point_start_date"
                      label="Point Start Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      :disabled="addItem.point_amount == ''"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addItem.point_start_date"
                    :min="new Date().toISOString().substr(0, 10)"
                    @input="menu3 = false"
                    class="ma-0"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="menu4"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="addItem.point_end_date"
                      label="Point End Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      :disabled="addItem.point_amount == ''"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addItem.point_end_date"
                    :min="addItem.point_start_date"
                    @input="menu4 = false"
                    class="ma-0"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  dense
                  v-model="addItem.discount_type"
                  :items="discount_types"
                  label="Discount type"
                  outlined
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="addItem.discount_amount"
                  label="Discount Amount"
                  type="number"
                  outlined
                  dense
                  :disabled="addItem.discount_type == 'none'"
                >
                  <v-icon
                    slot="append"
                    color="red"
                    v-if="addItem.discount_type == 'amount'"
                    >mdi-currency-bdt</v-icon
                  >
                  <v-icon
                    slot="append"
                    color="red"
                    v-if="addItem.discount_type == 'percentage'"
                    >mdi-percent</v-icon
                  >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="addItem.discount_start_date"
                      label="Discount Start Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      :disabled="addItem.discount_type == 'none'"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addItem.discount_start_date"
                    :min="new Date().toISOString().substr(0, 10)"
                    @input="menu = false"
                    class="ma-0"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="addItem.discount_end_date"
                      label="Discount End Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      :disabled="addItem.discount_type == 'none'"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addItem.discount_end_date"
                    :min="addItem.discount_start_date"
                    @input="menu2 = false"
                    class="ma-0"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <p class="mb-0 ml-1">Delivery Information</p>
              <v-col cols="12" class="pa-0">
                <v-radio-group
                  v-model="addItem.option"
                  row
                  @change="deliveryOptionChanged(addItem.option)"
                >
                  <v-radio label="All Over Bangladesh" value="all"></v-radio>
                  <v-radio label="Select Districts" value="manual"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <div v-show="addItem.option == 'manual'">
              <v-row
                v-for="(delivery, index) in addItem.arrayForDeliveryCopy"
                :key="index"
              >
                <v-col cols="3">
                  <v-select
                    :items="districts"
                    item-text="Area_name"
                    :menu-props="{ top: true, offsetY: true }"
                    :disabled="delivery.active"
                    label="Select District"
                    solo
                    v-model="delivery.selectedDistrict"
                    @change="onSelectDistrict(delivery)"
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    v-model="delivery.selectedThana"
                    :items="delivery.thanas"
                    item-text="location_name"
                    :menu-props="{ top: true, offsetY: true }"
                    :disabled="delivery.active"
                    chips
                    dense
                    label="Select Thanas"
                    multiple
                    solo
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 3">
                        <span>{{ item.location_name }}</span>
                      </v-chip>
                      <span v-if="index == 3" class="grey--text caption">
                        (+{{ delivery.selectedThana.length - 3 }}
                        others)
                      </span>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="2">
                  <!-- index + 1 ==
                                      addItem.arrayForDeliveryCopy.length && -->
                  <v-row>
                    <!-- v-show="districtsArrayForCalculation.length" -->
                    <v-btn
                      fab
                      small
                      color="primary"
                      :disabled="
                        addItem.arrayForDeliveryCopy.length == districts.length
                      "
                      @click="increaseDeliveryLength(index)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <!-- v-show="index > 0" -->
                    <v-btn
                      fab
                      small
                      color="red"
                      :disabled="addItem.arrayForDeliveryCopy.length == 1"
                      @click="deleteDeliveryLength(index)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-form>

          <v-card-title class="py-2 px-0 font-weight-bold"
            >Warehouse</v-card-title
          >
          <v-divider></v-divider>
          <v-simple-table fixed-header height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Warehouse Name</th>
                  <th class="text-left">Previous Quantity</th>
                  <th class="text-center">Add Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in warehouse" :key="index">
                  <td>{{ item.warehouse_name }}</td>
                  <td>{{ item.previous_quantity }}</td>
                  <td class="d-flex justify-center">
                    <v-form lazy-validation>
                      <v-text-field
                        outlined
                        type="number"
                        style="max-width: 100px; height: 50px"
                        class="mt-2"
                        flat
                        dense
                        v-model.number="item.quantity"
                        @input="warehouseAdd(item)"
                      ></v-text-field>
                    </v-form>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-divider></v-divider>
          <v-card-title class="py-2 px-0 font-weight-bold">Shop</v-card-title>
          <v-divider></v-divider>

          <v-simple-table fixed-header height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Shop Name</th>
                  <th class="text-left">Previous Quantity</th>
                  <th class="text-center">Add Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in shop" :key="index">
                  <td>{{ item.shop_name }}</td>
                  <td class="text-left">{{ item.previous_quantity }}</td>
                  <td class="d-flex justify-center">
                    <v-form>
                      <v-text-field
                        outlined
                        type="number"
                        style="max-width: 100px; height: 50px"
                        class="mt-2"
                        flat
                        dense
                        v-model="item.quantity"
                        @input="shopAdd(item)"
                      ></v-text-field>
                    </v-form>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            elevation="0"
            @click="confirmquantity('Approved')"
            :disabled="!priceValidationForm"
          >
            <p class="white--text mb-0 font-weight-medium text-body-2">
              Confirm Product
            </p>
          </v-btn>
          <v-btn
            @click="confirmquantity('Cancelled')"
            color="red"
            dark
            elevation="0"
            class="mx-2"
            >Cancel Product</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Message BOX -->
    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
       


<script>
export default {
  data: () => ({
    dialog: false,
    confirm_cancel: false,
    loading : true,
    invoiceinfo: [],
    orders: [],
    orderinfo: [],
    contact: {},
    bill: {},
    specification: {
      discount: {},
      point: {},
      delivery_info: {},
      price: {},
    },
    companyinfo: {},

    menu: false,
    snackbar: false,
    text: "",
    color: "",

    headers: [
      {
        text: "Short Description",
        value: "product_name",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2",
      },
      {
        text: "Unit Price ",
        value: "unit_price",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2",
      },
      {
        text: "Quantity",
        align: "start",
        sortable: false,
        value: "total_quantity",
        class: "blue-grey lighten-4 text-body-2",
      },
      {
        text: "Amount",
        value: "total_price",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2",
      },
      {
        text: "Status",
        value: "admin_status",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2",
      },
      {
        text: "Details",
        value: "details",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2",
      },
      {
        text: "Action",
        value: "actions",
        sortable: false,
        class: "blue-grey lighten-4 text-body-2",
      },
    ],

    quantityIndex: -1,
    quantityID: -1,
    addquantitydialog: false,
    menu: false,
    menu2: false,
    menu3: false,
    menu4: false,
    priceValidationForm: false,
    shop: [],
    warehouse: [],
    addedwarehousequantity: [],
    addedshopquantity: [],
    districts: [],
    selectedDistrict: "",
    thanas: [],
    selectedThana: [],
    addItem: {
      discount_type: "none",
      discount_amount: "",
      discount_start_date: "",
      discount_end_date: "",

      point_amount: "",
      point_start_date: "",
      point_end_date: "",

      option: "all",
      arrayForDeliveryCopy: [],
    },

    defaultItem: {
      discount_type: "none",
      discount_amount: "",
      discount_start_date: "",
      discount_end_date: "",

      point_amount: "",
      point_start_date: "",
      point_end_date: "",

      option: "all",
      arrayForDeliveryCopy: [],
    },

    discount_types: ["none", "amount", "percentage"],
    finalArray: [],
  }),

  watch: {
    addquantitydialog: function (newValue) {
      // IF add quantity and price modal is closed then reset all the variable
      if (!newValue) {
        // this.quantityIndex = -1;
        // this.quantityID = -1;
        // this.$refs.priceForm.reset();
        // Object.assign(this.addItem, this.defaultItem);
      } else {
        this.addedwarehousequantity.length = 0;
        this.addedshopquantity.length = 0;
      }
    },
  },

  methods: {
    initialize(item) {
      axios
        .get(`Cart/seller_individual_invoice/${this.$route.query.id}`)
        .then((response) => {
          this.invoiceinfo = response.data.invoice_data;
          this.orderinfo = response.data.order_data;
          this.bill = response.data.seller_info;
          this.orders = response.data.order_data.orders;
          this.companyinfo = response.data.company_info;
          this.loading = false
        });
    },

    // print(dataapp) {
    //   var divToPrint = document.getElementById("dataapp");

    //   var style = "<style>";
    //   style = style + "table {width: 100%;font: 17px Calibri;}";
    //   style =
    //     style +
    //     "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    //   style = style + "padding: 2px 3px;text-align: center;}";
    //   style = style + "</style>";

    //   var win = window.open("", "", "height=1900,width=1900");
    //   win.document.write(style); //  add the style.
    //   win.document.write(divToPrint.outerHTML);
    //   win.document.close();
    //   win.print();
    // },

    CancelInvoice(item) {
      axios
        .get(`productdetails/cancel_invoice/${this.invoiceinfo.id}`)
        .then((response) => {
          // console.log("RES ", response.data);
          if (response.data.success) {
            this.orderinfo.admin_status = "Cancelled";

            this.color = "red";
            this.text = "Order Cancelled";
            this.confirm_cancel = false;
            this.snackbar = true;

            setTimeout(
              (_) =>
                this.$router.push({
                  path: "/supportdashboard/pages/invoices",
                }),
              2000
            );
          } else {
            this.color = "red";
            this.text = "Something went wrong";
            this.confirm_cancel = false;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.color = "red";
          this.text = "Something went wrong";
          this.confirm_cancel = false;
          this.snackbar = true;
        });
    },

    assignViewedItem(item) {
      // console.log("assign ", item);
      // Object.assign(this.specification, item);
      axios
        .get(`productdetails/individual_seller_spec/${item.specification_id}/`)
        .then((response) => {
          // console.log("RES ", response.data);
          Object.assign(this.specification, response.data.data);
          this.dialog = true;
        });
    },

    ADDQuantity(item) {
      Object.assign(this.addItem, item);
      this.quantityID = item.id;
      this.quantityIndex = this.orders.findIndex((spec) => spec.id == item.id);

      axios
        .get(`productdetails/quantity_info/${item.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.shop = response.data.data.shop;
            this.warehouse = response.data.data.warehouse;
            this.addquantitydialog = true;
          } else {
            this.color = "red";
            this.text = "Something went wrong !";
            //this.text = response.data.message;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.color = "red";
          this.text = "Something went wrong !";
          //this.text = response.data.message;
          this.snackbar = true;
        });
    },

    warehouseAdd(item) {
      //check array length for new item or not
      if (this.addedwarehousequantity.length > 0) {
        let index = this.addedwarehousequantity.findIndex(
          (warhouse) => warhouse.warehouse_id == item.warehouse_id
        );

        if (index != -1) {
          this.addedwarehousequantity[index].quantity = item.quantity;
        } else {
          this.addedwarehousequantity.push(item);
        }
      } else {
        this.addedwarehousequantity.push(item);
      }
    },

    shopAdd(item) {
      if (this.addedshopquantity.length > 0) {
        let index = this.addedshopquantity.findIndex(
          (shop) => shop.shop_id == item.shop_id
        );
        if (index != -1) {
          this.addedshopquantity[index].quantity = item.quantity;
        } else {
          this.addedshopquantity.push(item);
        }
      } else {
        this.addedshopquantity.push(item);
      }
    },

    deliveryOptionChanged(deliveryOption) {
      this.addItem.arrayForDeliveryCopy.length = 0;

      if (deliveryOption != "all") {
        this.addItem.arrayForDeliveryCopy.push({
          selectedDistrict: "",
          selectedThana: [],
          thanas: [],
          active: false,
        });
      }
    },

    increaseDeliveryLength(index) {
      this.addItem.arrayForDeliveryCopy[index].active = true;
      if (this.districts.length == this.addItem.arrayForDeliveryCopy.length) {
        this.text = "Please delete an district first";
        this.color = "warning";
        this.snackbar = true;
      } else {
        this.addItem.arrayForDeliveryCopy.push({
          selectedDistrict: "",
          selectedThana: [],
          thanas: [],
          active: false,
        });
      }
    },

    deleteDeliveryLength(index) {
      this.addItem.arrayForDeliveryCopy.splice(index, 1);
    },

    onSelectDistrict(delivery) {
      // Make API call to fetch available thana under selected district
      axios
        .get(`supports/getlocation/${delivery.selectedDistrict}`)
        .then((response) => {
          this.addItem.arrayForDeliveryCopy[
            this.addItem.arrayForDeliveryCopy.length - 1
          ].thanas = response.data.data;
        });
    },

    // Quantity and Price addition
    confirmquantity(status) {
      let newItem = Object.assign({}, this.addItem);

      let newArray = JSON.parse(
        JSON.stringify(this.addItem.arrayForDeliveryCopy)
      );

      let datas = {
        // product_id: this.contract[0].product_id,
        order_details_id: this.addItem.id,
        warehouse: JSON.parse(JSON.stringify(this.addedwarehousequantity)),
        shop: JSON.parse(JSON.stringify(this.addedshopquantity)),
        selling_price: Number(this.addItem.price),
        // ...this.addItem,
        arrayForDelivery: newArray,
        ...newItem,
      };

      // console.log("ADDITEM ", datas);

      let index = this.orders.findIndex(
        (element) => element.id == this.addItem.id
      );

      if (status == "Approved") {
        let total = 0;

        datas.warehouse.forEach((element) => {
          total = Number(total) + Number(element.quantity);
        });

        datas.shop.forEach((element) => {
          total = Number(total) + Number(element.quantity);
        });

        if (total == this.addItem.total_quantity) {
          // this.text = `Successfull`;
          // this.color = "success";

          this.orders[index].admin_status = "Approved";
          datas.product_status = "Approved";

          this.finalArray.push(datas);

          // this.$refs.priceForm.reset();
          this.addItem = Object.assign({}, this.defaultItem);

          this.addquantitydialog = false;
          // this.snackbar = true;
        } else if (total > this.addItem.total_quantity) {
          this.text = `You can add maximum ${this.addItem.total_quantity} products to your warehouse / shop`;
          this.color = "warning";
          this.snackbar = true;
        } else {
          this.text = `You have to add  ${this.addItem.total_quantity} products to your warehouse / shop`;
          this.color = "warning";
          this.snackbar = true;
        }
      } else {
        this.orders[index].admin_status = "Cancelled";
        datas.product_status = "Cancelled";

        this.finalArray.push(datas);

        // this.$refs.priceForm.reset();
        this.addItem = Object.assign({}, this.defaultItem);

        this.addquantitydialog = false;
      }

      // // // If user did not add any quantity throw error
      // // if (!total) {
      // //   this.text = "Quantity can not be empty";
      // //   this.color = "warning";
      // //   this.snackbar = true;
      // // } else {
      // //   // If quantity and price exists
      // //   axios
      // //     .post(`/productdetails/insert_quantity/`, datas)
      // //     .then((response) => {
      // //       if (response.data.success) {
      // //         this.contract[this.quantityIndex].quantity =
      // //           this.contract[this.quantityIndex].quantity + total;
      // //         this.text = "Quantity has been updated successfully";
      // //         this.color = "success";
      // //         this.addquantitydialog = false;
      // //         this.snackbar = true;
      // //       } else {
      // //         this.text = "Something went wrong ";
      // //         this.color = "warning";
      // //         this.addquantitydialog = false;
      // //         this.snackbar = true;
      // //       }
      // //     })
      // //     .catch((err) => {
      // //       this.text = "Something went wrong ";
      // //       this.color = "warning";
      // //       this.addquantitydialog = false;
      // //       this.snackbar = true;
      // //     });
      // }
    },

    AllConfirm() {
      // console.log("this is last array", this.finalArray);

      axios
        .post(`productdetails/seller_insert_quantity/`, this.finalArray)
        .then((response) => {
          if (response.data.success) {
            this.color = "primary";
            this.text = response.data.message;
            this.snackbar = true;

            this.orderinfo.admin_status = "Confirmed";

            setTimeout(
              (_) =>
                this.$router.push({
                  path: "/supportdashboard/pages/invoices",
                }),
              2000
            );
          } else {
            this.color = "red";
            this.text = response.data.message;
            this.snackbar = true;

            this.orderinfo.admin_status = "Cancelled";

            setTimeout(
              (_) =>
                this.$router.push({
                  path: "/supportdashboard/pages/invoices",
                }),
              2000
            );
          }
        })
        .catch((err) => {
          this.text = `Something went wrong`;
          this.color = "success";
          this.snackbar = true;
        });
    },
  },
  mounted() {
    this.initialize();

    // Fetch all available Districts
    axios.get(`supports/allareas`).then((response) => {
      if (response.data.success) {
        this.districts = response.data.data;
      }
    });
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}

.demo {
  background-color: #ff7743;
}

</style>